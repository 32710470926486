/*
    A service used to trigger GTM pushes
*/
webClient.factory('trackingService', ['$window', 'gtmModel', 'gtmProduct', 'scopeParser', '$i18next',
    function ($window, gtmModel, gtmProduct, scopeParser, $i18next) {
        return {

            isActivated: function () {
                return typeof $window.dataLayer !== 'undefined' && $window.dataLayer != null;
            },

            initDataLayer: function (name) {
                $scope.gtm = new gtmModel(name);
            },

            /*
                Reload data using JSON stored in window.sessionStorage
                The action name / value is used as key 
            */
            loadSessionData: function (action, value) {
                return $scope.gtm.loadSessionData(action, value);
            },

            /*
                Use to send page views. Distinct gtm object in order not to mess with ecommerce tunnel
            */
            pushPageData: function (page, title, language, fireEvent, args) {

                if ($window.dataLayer) {

                    if (fireEvent) {    //fireEvent is true: the datalayer is pushed immediately
                        var gtm = {
                            event: 'Pageview',
                            page: page,
                            title: title,
                            language: language
                        };

                        var datalayer = JSON.stringify(gtm);

                        console.log(datalayer);

                        $window.dataLayer.push(gtm);
                    }
                    else {  //fireEvent is false: data is stored to be pushed with ecommerce data
                        $window.GtmPageEvent = {
                            event: 'Pageview',
                            page: page,
                            title: title,
                            language: language
                        };
                    }
                }
            },

            addProduct: function (product) {
                $scope.gtm.addProduct(product);
            },

            removeProduct: function (id) {
                try {
                    $scope.gtm.removeProduct(id);
                }
                catch (ex) {
                    //avoid log in Safari
                }
            },

            setOption: function (value) {
                $scope.gtm.setAction('option', value);
            },

            parseScope: function (value) {
                //do nothing for KARMA
            },

            /*
                Serializes the GTM model and pushes the datalayer
            */
            pushDataLayer: function (action, value) {
                // depends on <Item Type="Inline" Id="googleTagManager"> in xml file: if empty, no datalayer is declared and nothing will be pushed
                if ($window.dataLayer) {

                    var overrideAction = action != null && value != null;

                    if (overrideAction) {
                        $scope.gtm.setAction(action, value);
                    }

                    if ($window.GtmPageEvent) { //if dtata has been stored, add them to datalayer before sending it
                        $scope.gtm['event'] = $window.GtmPageEvent.event;
                        $scope.gtm['page'] = $window.GtmPageEvent.page;
                        $scope.gtm['title'] = $window.GtmPageEvent.title;
                        $scope.gtm['language'] = $window.GtmPageEvent.language;
                    }

                    $scope.gtm.Origin_Country = scopeParser.getOriginCountryISO();                    
                    $scope.gtm.Destination_Country = scopeParser.getDestinationCountryISO();

                    var originContinent = scopeParser.tryGetOriginContinent();
                    var destinationContinent = scopeParser.tryGetDestinationContinent();

                    if (originContinent != null) {
                        $scope.gtm.Origin_Continent = originContinent;
                    }

                    if (destinationContinent != null) {
                        $scope.gtm.Destination_Continent = destinationContinent;
                    }


                    $scope.gtm.Origin_City = scopeParser.getOriginCityIATA();
                    $scope.gtm.Destination_City = scopeParser.getDestinationCityIATA();

                    var originAirport = scopeParser.getOriginIATA();
                    var destinationAirport = scopeParser.getDestinationIATA();


                    if (originAirport != $scope.gtm.Origin_City) {
                        $scope.gtm.Origin_Airport = originAirport;
                    }

                    if (destinationAirport != $scope.gtm.Destination_City) {
                        $scope.gtm.Destination_Airport = destinationAirport;
                    }

                    //check if we can get the value from purchase
                    originAirport = scopeParser.tryGetPurchaseOriginAirportIATA();
                    destinationAirport = scopeParser.tryGetPurchaseDestinationAirportIATA();

                    if (originAirport != null) {
                        $scope.gtm.Origin_Airport = originAirport;
                    }

                    if (destinationAirport != null) {
                        $scope.gtm.Destination_Airport = destinationAirport;
                    }
                    
                    $scope.gtm.Departure_Date = scopeParser.getDepartingDateWithDash();
                    $scope.gtm.Return_Date = scopeParser.getReturningDateWithDash();

                    $scope.gtm.Nb_PAX = scopeParser.getPaxNumber();
                    $scope.gtm.Nb_Adults = scopeParser.getAdultNumber();
                    $scope.gtm.Nb_Childen = scopeParser.getChildNumber();
                    $scope.gtm.Nb_Infants = scopeParser.getInfantNumber();
                    $scope.gtm.Product_Type = 'flights-MFLY';

                    var company = scopeParser.getFirstCompanyIATA();

                    if (company != null) {
                        $scope.gtm.Airline = scopeParser.getFirstCompanyIATA();
                    }

                    //payment confirmation
                    if (action == null && value == null) {
                        var sku = new Date().toISOString().substring(0, 10) + "_";
                        sku += scopeParser.getItinerariesAsString() + "_";
                        sku += $scope.gtm.Nb_PAX + "P_";
                        sku += $scope.gtm.Nb_Adults + "A_";
                        sku += $scope.gtm.Nb_Childen + "E_";
                        sku += $scope.gtm.Nb_Infants + "B_";
                        sku += scopeParser.getOriginCityIATA() + "_";
                        sku += scopeParser.getDestinationCityIATA();
                        $scope.gtm.Product_SKU = sku;

                        //copy SKU into first product ID but only on billing conf
                        $scope.gtm.ecommerce.purchase.products[0].id = sku;

                    }

                    //remove properties prefixed by _
                    var datalayer = JSON.stringify($scope.gtm).replace(/("_[^"]+":"[^"]+",?)/, "");

                    console.log(datalayer);

                    if (overrideAction) {
                        $window.sessionStorage.setItem('gtmModel-' + action + '-' + value, JSON.stringify($scope.gtm));
                    }
                    $window.dataLayer.push(JSON.parse(datalayer));
                }
            },

            /* 
            * New tag plan helper functions for travel juice partner
            */

            clickFlightSearch: function () {
                var vSearchType = document.getElementsByName('i-search-type')[0].value;
                var vCabinClass = document.getElementsByName('cabinClass')[0].value;
                var vAdults = document.getElementsByName('adults').length > 0 ? document.getElementsByName('adults')[0].value : 1;
                var vChilds = document.getElementsByName('childs').length > 0 ? document.getElementsByName('childs')[0].value : 0;
                var vInfants = document.getElementsByName('infants').length > 0 ? document.getElementsByName('infants')[0].value : 0;

                var originInfo = JSON.parse(window.localStorage.getItem('origin_info'));

                var legs = parseInt($('.c-segment .c-field').length);
                var lastDestinationKey = 'destination_info';
                if (legs > 1) {
                    lastDestinationKey = 'destination-' + legs + '_info';
                }

                var destinationInfo = JSON.parse(window.localStorage.getItem(lastDestinationKey));

                var sharedDataLayerValues = {
                    'flight_type': vSearchType,
                    'flight_class': vCabinClass,
                    'destination_continent': destinationInfo.continent,
                    'destination_country': destinationInfo.country,
                    'destination_city': destinationInfo.city,
                    'origin_continent': originInfo.continent,
                    'origin_country': originInfo.country,
                    'origin_city': originInfo.city,
                    'nb_adults': vAdults,
                    'nb_childen': vChilds,
                    'nb_infants': vInfants,
                    'nb_PAX': (parseInt(vAdults) + parseInt(vChilds) + parseInt(vInfants)),
                    'desti': destinationInfo.city,
                    'product_type': 'flights-MFLY',
                };

                localStorage.setItem('shared_data_layer_values', JSON.stringify(sharedDataLayerValues));

                var specificDataLayerValues = {
                    'departure_date': document.getElementsByName('goDate')[0].value,
                    'return_date': document.getElementsByName('returnDate')[0].value,
                    'CTA_title': getT('homepage.searchform.search'),
                    'redirectfamily': 'redirectfamily',
                    'redirection_full_URL': 'redirection_full_URL',
                    'type': 'type',
                    'component_name': 'flight search form',
                    'event': 'click_flight_search',
                };

                var dataToSend = Object.assign({}, sharedDataLayerValues, specificDataLayerValues);

                return dataToSend;
            },
            addToCartOutbound: function (product) {
                var outbound = product.outbound;
                var specificDataLayerValues = {
                    'airline': outbound.marketingCarrier,
                    'destination_airport': outbound.arrival,
                    'origin_airport': outbound.departure,
                    'departure_date': outbound.departureDate,
                    'return_date': outbound.arrivalDate,
                    'brand': outbound.marketingCarrier,
                    'basket_value': (product.inbounds != null && product.inbounds.length > 0) ? product.inbounds[0].proposal.totalFare.value : 'not available',
                    'CTA_title': '',
                    'redirectfamily': 'redirectfamily',
                    'redirection_full_URL': 'redirection_full_URL',
                    'type': 'type',
                    'component_name': 'air product body',
                    'event': 'addtocart_outbound'
                }

                var sharedDataLayerValues = JSON.parse(localStorage.getItem('shared_data_layer_values'));
                var dataToSend = Object.assign({}, sharedDataLayerValues, specificDataLayerValues);

                return dataToSend;
            },
            addToCartReturn: function (inbound) {
                var specificDataLayerValues = {
                    'airline': inbound.marketingCarrier,
                    'destination_airport': inbound.arrival,
                    'origin_airport': inbound.departure,
                    'departure_date': inbound.departureDate,
                    'return_date': inbound.arrivalDate,
                    'product_type': inbound.product_type,
                    'basket_value': inbound.proposal.totalFare.value,
                    'brand': inbound.marketingCarrier,
                    'CTA_title': '',
                    'redirectfamily': 'redirectfamily',
                    'redirection_full_URL': 'redirection_full_URL',
                    'type': 'type',
                    'component_name': 'air product body',
                    'event': 'addtocart_return'
                }

                var sharedDataLayerValues = JSON.parse(localStorage.getItem('shared_data_layer_values'));
                var dataToSend = Object.assign({}, sharedDataLayerValues, specificDataLayerValues);

                return dataToSend;
            },
            flightOption: function () {
                var sharedDataLayerValues = JSON.parse(localStorage.getItem('shared_data_layer_values'));

                var bkgData = $window.$scope.bkgData;

                var addedSharedDataLayerValues = {
                    'airline': bkgData.purchaseResponse.platingCarrier,
                    'destination_airport': bkgData.purchaseResponse.itineraries[bkgData.purchaseResponse.itineraries.length - 1].destination.airportIata,
                    'origin_airport': bkgData.purchaseResponse.itineraries[0].origin.airportIata,
                    'departure_date': bkgData.purchaseResponse.itineraries[0].date,
                    'return_date': bkgData.purchaseResponse.itineraries.length > 1 ? bkgData.purchaseResponse.itineraries[bkgData.purchaseResponse.itineraries.length - 1].date : '',
                    'flex': bkgData.textFlexSelected,
                    'basket_value': bkgData.totalBasketPrice(),
                    'luggage': scopeParser.retrieveLuggagesSelected(),
                };

                var newSharedDataLayerValues = Object.assign({}, sharedDataLayerValues, addedSharedDataLayerValues);

                localStorage.setItem('shared_data_layer_values', JSON.stringify(newSharedDataLayerValues));

                var specificDataLayerValues = {
                    'CTA_title': $i18next.t("airbooking.continue"),
                    'redirectfamily': 'redirectfamily',
                    'redirection_full_URL': 'redirection_full_URL',
                    'type': 'type',
                    'component_name': 'flight booking body',
                    'event': 'flight_option'
                }

                var dataToSend = Object.assign({}, newSharedDataLayerValues, specificDataLayerValues);

                return dataToSend;
            },
            flightInsurance: function () {
                var sharedDataLayerValues = JSON.parse(localStorage.getItem('shared_data_layer_values'));

                var bkgData = $window.$scope.bkgData;

                var addedSharedDataLayerValues = {
                    'insurance': scopeParser.retrieveInsuranceSelected(),
                };

                sharedDataLayerValues.basket_value = bkgData.totalBasketPrice();

                var newSharedDataLayerValues = Object.assign({}, sharedDataLayerValues, addedSharedDataLayerValues);

                localStorage.setItem('shared_data_layer_values', JSON.stringify(newSharedDataLayerValues));
                var specificDataLayerValues = {
                    'CTA_title': $i18next.t("airbooking.continue"),
                    'redirectfamily': 'redirectfamily',
                    'redirection_full_URL': 'redirection_full_URL',
                    'type': 'type',
                    'component_name': 'flight booking body',
                    'event': 'flight_insurance'
                }

                var dataToSend = Object.assign({}, newSharedDataLayerValues, specificDataLayerValues);

                return dataToSend;
            },
            addPaymentInfo: function () {
                var bkgData = $window.$scope.bkgData;

                var sharedDataLayerValues = JSON.parse(localStorage.getItem('shared_data_layer_values'));

                var ecommerceValue = {
                    'currency': bkgData.purchaseResponse.currency,
                    'value': bkgData.totalBasketPrice(),
                    'coupon': bkgData.bookingRequest.holidayVoucher,
                    'payment_type': bkgData.bookingRequest.paymentMethod.paymentMethodReference
                }

                var addedSharedDataLayerValues = {
                    'ecommerce': ecommerceValue,
                };

                var newSharedDataLayerValues = Object.assign({}, sharedDataLayerValues, addedSharedDataLayerValues);

                localStorage.setItem('shared_data_layer_values', JSON.stringify(newSharedDataLayerValues));

                var specificDataLayerValues = {
                    'CTA_title': $i18next.t("airbooking.infospayeur.continue"),
                    'redirectfamily': 'redirectfamily',
                    'redirection_full_URL': 'redirection_full_URL',
                    'type': 'type',
                    'component_name': 'flight booking body',
                    'event': 'add_payment_info'
                }

                var dataToSend = Object.assign({}, newSharedDataLayerValues, specificDataLayerValues);
                return dataToSend;
            },
            proceedToPayment: function () {
                var sharedDataLayerValues = JSON.parse(localStorage.getItem('shared_data_layer_values'));

                var addedSharedDataLayerValues = {
                };

                var newSharedDataLayerValues = Object.assign({}, sharedDataLayerValues, addedSharedDataLayerValues);

                localStorage.setItem('shared_data_layer_values', JSON.stringify(newSharedDataLayerValues));

                var specificDataLayerValues = {
                    'CTA_title': 'J\'accepte et je paie',
                    'redirectfamily': 'redirectfamily',
                    'redirection_full_URL': 'redirection_full_URL',
                    'type': 'type',
                    'component_name': 'Air payment Body Blue',
                    'event': 'proceed_to_payment'
                }

                var dataToSend = Object.assign({}, newSharedDataLayerValues, specificDataLayerValues);
                return dataToSend;
            },
            orderConfirmation: function () {
                var sharedDataLayerValues = JSON.parse(localStorage.getItem('shared_data_layer_values'));

                var addedSharedDataLayerValues = {
                };

                var newSharedDataLayerValues = Object.assign({}, sharedDataLayerValues, addedSharedDataLayerValues);

                localStorage.setItem('shared_data_layer_values', JSON.stringify(newSharedDataLayerValues));

                var specificDataLayerValues = {
                    'CTA_title': '',
                    'redirectfamily': 'redirectfamily',
                    'redirection_full_URL': 'redirection_full_URL',
                    'type': 'type',
                    'component_name': 'Air payment response body',
                    'event': 'order_confirmation'
                }

                var dataToSend = Object.assign({}, newSharedDataLayerValues, specificDataLayerValues);

                return dataToSend;
            },
            retrieveDataFromStep: function (stepID, data) {
                switch (stepID) {
                    case 'click_flight_search':
                        return this.clickFlightSearch();
                    case 'addtocart_outbound':
                        return this.addToCartOutbound(data);
                    case 'addtocart_return':
                        return this.addToCartReturn(data);
                    case 'flight_option':
                        return this.flightOption();
                    case 'flight_insurance':
                        return this.flightInsurance();
                    case 'add_payment_info':
                        return this.addPaymentInfo();
                    case 'proceed_to_payment':
                        return this.proceedToPayment();
                    case 'order_confirmation':
                        return this.orderConfirmation();
                    default:
                        return {};
                }
            },

            neoDataLayer: function (stepID, data) {
                var dataToSend = this.retrieveDataFromStep(stepID, data);

                if (stepID == 'click_flight_search') {
                    localStorage.setItem('mftracking', JSON.stringify({}));
                }

                var incrementalDatalayer = JSON.parse(window.localStorage.getItem('mftracking'));
                var stepDatalayer = {};
  
                if (stepID == 'order_confirmation') {
                    dataToSend = Object.assign({}, incrementalDatalayer['click_flight_search'], incrementalDatalayer['addtocart_outbound'],
                        incrementalDatalayer['addtocart_return'], incrementalDatalayer['flight_option'], incrementalDatalayer['flight_insurance'],
                        incrementalDatalayer['add_payment_info'], incrementalDatalayer['proceed_to_payment'], dataToSend);
                }
                stepDatalayer[stepID] = dataToSend;
                var newData = Object.assign({}, incrementalDatalayer, stepDatalayer);
                if ($window.dataLayer) {
                    console.log("données envoyées dans le neo data layer");
                    console.log(JSON.stringify(newData));
                    localStorage.setItem('mftracking', JSON.stringify(newData));
                    $window.dataLayer.push(newData);
                }
            }
        };
    }]);

